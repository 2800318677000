


























































 import { Notify,Toast,Dialog } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import IssueCont from "@/components/HandComponent/Issue/IssueCont.vue"
 import IssueMoney from "@/components/HandComponent/Issue/IssueMoney.vue"
 import Stroage from "@/util/Storage"
 import { UserType } from "@/Api/Basics/InterfaceType"
 import { HandIssueUpData } from "@/Api/Hand/interfaceType"
 import { HandTypeT } from "@/Type/index"
 import { HandStore } from "@/store/modules/Hand/Hand"
 import { HandIssue } from "@/Api/Hand/index"
 import { Vue,Component } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface IssueHandType<T=string>{
     loadingShow:boolean;
     userId:T|number;
     token:T;
     RightIcon:T;
     SiteIcon:T;
     TypeData:HandTypeT
     UpData:HandIssueUpData
     MoneyShow:boolean
     UserInfo:UserType
     MoneyTxt:T;
     MoneyBool:boolean

     handleClickShow():void;
     handleGetMoneyData(data:any):void;
     handleLocation():void;
     handleToPath():void;
     handleGetType(data:HandTypeT):void;
     handleGetValue(data:any):void;
     handleGetImgList(data:Array<string>):void;
     handleClickUpHand():void;
     handleError(data:string):void;
     handleClearData():void;
 }

 @Component({ name:"IssueHand",components:{ IssueCont,IssueMoney,HeadTop } })
 export default class IssueHand extends ZoomPage implements IssueHandType{
    loadingShow = false
    userId: string|number = ""
    token = ""
    SiteIcon = require("$icon/Hand/address.png");
    RightIcon = require("$icon/Basics/Right.png")
    TypeData:HandTypeT = {}
    UpData:HandIssueUpData = {
        title:"",
        introduce:"",
        pics:"",
        price:0,
        originalPrice:0,
        categoryId:"",
        count:0,
        mobile:"",
    }
    MoneyShow = false
    UserInfo:UserType = {}
    MoneyTxt = "请输入售卖价格"
    MoneyBool = false

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.TypeData = HandStore.GetHandIssueActiveT
        try{
            this.UserInfo = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") )
            this.UpData.mobile = this.UserInfo.phone as string

            let Data  = HandStore.GetHandleIssueData.MoneyData
            if( Data.Money > 0 ){
                this.MoneyTxt = "￥ " + Data.Money + "元"
                this.MoneyBool = true
                this.UpData.price = Data.Money
                this.UpData.originalPrice = Data.Price
                this.UpData.count = Data.Num
            }
        }catch(cat){ }
    }

    handleClickShow(){
        this.MoneyShow = !this.MoneyShow
    }

    handleGetMoneyData(data:any){
        this.UpData.price = data.Money
        this.UpData.originalPrice = data.Price
        this.UpData.count = data.Num
        this.MoneyTxt = "￥ " + data.Money + "元"
        this.MoneyBool = true
    }

    handleLocation(){
        Toast({
        message:"暂时不开放",
        icon:"none",
        className:"ToastClassName"
        })
    }

    handleToPath(){
        this.$router.replace({
            path:"/handTypeList"
        })
    }

    handleGetType(data:HandTypeT){
        if( !( "id" in HandStore.GetHandIssueActiveT ) ){
            this.TypeData = data
            this.UpData.categoryId = data.id as string
        }
    }

    handleGetValue(data:any){
        this.UpData.title = data.title
        this.UpData.introduce = data.content
    }

    handleGetImgList(data:Array<string>){
        let Str = ""
        data.forEach( (item,index)=>{
            if( index ){
                Str += "," + item
            }else{
                Str += item
            }
        } )
        this.UpData.pics = Str
    }

    handleClickUpHand(){
        let { UpData } = this;
        if( !(UpData.title + "").length ){
            this.handleError("请输入标题")
            return;
        }
        if( !(UpData.introduce + "").length ){
            this.handleError("请输入描述商品")
            return;
        }
        if( Number(UpData.price) <= 0 ){
            this.handleError("请输入金额")
            return;
        }
        if( Number(UpData.originalPrice) <= 0 ){
            this.handleError("请输入原价")
            return;
        }
        if( Number(UpData.count) <= 0 ){
            this.handleError("请输入出售数量")
            return;
        }
        this.loadingShow = true
        HandIssue({
            userId:this.userId,
             token:this.token
        },UpData).then(res=>{
            console.log( res )
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.handleClearData()
                Dialog.confirm({
                title: '发布成功',
                message: '恭喜您发布成功,是否返回到二手广场?',
                className:"HideClassName",
                overlayClass:"MaskHideClassName"
                }).then(res=>{
                    this.$router.push({
                        path:"/allHand"
                    })
                }).catch(cat=>cat)
            }else{
                this.handleError(res.message.msg)
            }
        })
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    handleClearData(){
        this.UpData = {
            title:"",
            introduce:"",
            pics:"",
            price:0,
            originalPrice:0,
            categoryId:"",
            count:0,
            mobile:"",
        }
        let data = {
            Title:"",
            Content:"",
            ImgList:[],
            MoneyData:{
                Money:"",
                Price:"",
                Num:1,
                Fee:"",
                PackUp:false
            }
        }
        HandStore.SetHandIssueActiveT({})
        HandStore.SetHandIssueData(data)
    }
}
