






































 import { Notify } from "vant"
 import { ImagePreview } from 'vant';
 import Stroage from "@/util/Storage"
 import { HandTypeT } from "@/Type/index"
 import { HandStore } from "@/store/modules/Hand/Hand"
 import { HandType } from "@/Api/Hand/index"
 import { UpPhotos } from "@/Api/UpPhoto/index"
 import { Vue,Component, Emit, Watch } from "vue-property-decorator"

 interface IssueContType<T=string>{
     loadingShow:boolean
     loadingTxt:T;
     userId:T|number;
     token:T;
     AddIcon:T;
     Title:T;
     Cont:T;
     ImgList:Array<string>;
     TypeList:Array<HandTypeT>
     TypeData:HandTypeT

     initData():void;
     handlePreView(index:number):void;
     handleUpData():void;
     handleUpImgs(e:any):void;
     handleFilterImgs(data:Array<string>):void;
     handleSetData():void;
     handleError(data:string):void;
 }   

 @Component({ name:"IssueCont" })
 export default class IssueCont extends Vue implements IssueContType{
    loadingShow = false
    loadingTxt = "正在上传图片中..."
    userId:string|number = ""
    token = ""
    Title = ""
    Cont = ""
    ImgList: string[] = [  ]
    AddIcon = require("$icon/Basics/Add.png")
    defaultIcon = require("$icon/Hand/Test.png")
    TypeList:Array<HandTypeT> = []
    TypeData:HandTypeT = {}

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")  
        this.initData()   
    }

    initData(){
         if( HandStore.GetHandTypeList.length ){
             this.TypeList = HandStore.GetHandTypeList
             this.PullTypeData(this.TypeList[0])
             if( JSON.stringify( HandStore.GetHandIssueActiveT ) != "{}" ){
                 this.TypeData = HandStore.GetHandIssueActiveT
             }else{
                 this.TypeData = this.TypeList[0]
             }
         }else{
            this.handleUpData()
         }

         this.Title = HandStore.GetHandleIssueData.Title
         this.Cont = HandStore.GetHandleIssueData.Content
         this.ImgList = HandStore.GetHandleIssueData.ImgList
    }

    handlePreView(index:number){
        ImagePreview({
            images: this.ImgList,
            startPosition: index,
        });
    }

    handleUpImgs(e:any){
        let UpImg:Array<any> = []
        if( Array.isArray( e ) ){
            UpImg = e;
        }else{
            UpImg = [ e ]
        }
        let fromData = new FormData()
        UpImg.forEach( a=>fromData.append("files",a.file))
        fromData.append("functionName","other")
        this.loadingTxt = "正在上传图片中..."
        this.loadingShow = true
        UpPhotos({
             userId:this.userId,
             token:this.token
        },fromData).then( res=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.handleFilterImgs( res.data )
            }else{
                this.loadingTxt = "上传失败"
                this.handleError( res.message.msg )
            }
        } )
    }

    handleFilterImgs(data:Array<string>){
        let Num = 8 - this.ImgList.length
        if( Num ){
            this.ImgList = data.slice(0,Num)
        }
    }

    handleUpData(){
         this.loadingShow = true
         HandType({
             userId:this.userId,
             token:this.token
         }).then(res=>{
             this.loadingShow = false
             if( res.message.code === "200" ){
                 this.TypeList = res.data
                 this.PullTypeData(this.TypeList[0])
                 this.TypeData = this.TypeList[0]
                 HandStore.SetHandTypeList( res.data )
             }else{
                 this.handleError( res.message.msg )
             }
         })
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });            
    }

    handleSetData(){
        let data = HandStore.GetHandleIssueData
        data.Title = this.Title
        data.Content = this.Cont
        data.ImgList = this.ImgList
        HandStore.SetHandIssueData( data )
    }

    @Watch("Title")
    ChangeValue(newVal:string){
        this.PullValue({
            title:this.Title,
            content:this.Cont
        })
        this.handleSetData()
    }

    @Watch("Cont")
    ChangeContent(newVal:string){
        this.PullValue({
            title:this.Title,
            content:this.Cont
        })
        this.handleSetData()
    }

    @Watch("ImgList",{ deep:true })
    ChangeImgList(newVal:Array<string>){
        this.PullImgList( newVal )
        this.handleSetData()
    }

    @Watch("TypeData")
    ChangeTypeData(newVal:HandTypeT){
        HandStore.SetHandIssueActiveT( newVal )
    }

    @Emit("PullTypeData")
    PullTypeData(data:HandTypeT){
        return data
    }

    @Emit("PullValue")
    PullValue(data:{ title:string;content:string }){
        return data
    }

    @Emit("PullImgList")
    PullImgList(data:Array<string>){
        return data
    }
}
