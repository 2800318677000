








































































import { Toast } from "vant"
import { HandStore } from "@/store/modules/Hand/Hand"
import { Vue,Component, Prop, Watch, Emit } from "vue-property-decorator"

interface IssueMoneyType<T=string>{
    Money:number|T;
    Price:number|T;
    Num:number|T;
    Pre:number|T;
    Post:boolean
    NotIcon:T;
    A_Icon:T;
    Show:boolean

    initData():void;
    handleClickMask():void;
    handleClickBtn():void;
    handleSetData():void;
}

@Component({ name:"IssueMoney" })
export default class IssueMoney extends Vue implements IssueMoneyType{
   Money: string|number = ""
   Price: string|number = ""
   Num: string|number = ""
   Pre: string|number = ""
   Post = false
   NotIcon = require("$icon/Hand/NoActive.png");
   A_Icon = require("$icon/Hand/Active.png");
   Show = false
    
   mounted() {
       this.initData()   
   }

   initData(){
       let Data = HandStore.GetHandleIssueData.MoneyData
       this.Money = Data.Money
       this.Price = Data.Price
       this.Num = Data.Num
       this.Pre = Data.Fee
       this.Post = Data.PackUp
   }

   handleClickMask(){
       this.PullShow( false )
   }

   handleClickBtn(){
       let { Money,Price,Num } = this;
       if( Number(Money) <= 0 ){
           Toast({
           message:"请输入卖出价格",
           icon:"none",
           className:"ToastClassName"
           })
           return;
       }
       if( Number(Price) <= 0 ){
           Toast({
           message:"请输入当时购买价格",
           icon:"none",
           className:"ToastClassName"
           })
           return;
       }
       if( Number(Num) <= 0 ){
           Toast({
           message:"请输入出售数量",
           icon:"none",
           className:"ToastClassName"
           })
           return;
       }
       this.PullMoneyData({
           Money,Price,Num
       })
       this.PullShow( false )
   }

   handleSetData(){
       let Data = HandStore.GetHandleIssueData
       Data.MoneyData = {
           Money:this.Money,
           Price:this.Price,
           Num:this.Num,
           Fee:this.Pre,
           PackUp:this.Post
       }
       HandStore.SetHandIssueData( Data )
   }

   @Prop(Boolean)
   show!:boolean

   get GetShow(){
       return this.show
   }

   @Watch( "GetShow" )
   ChangeGetShow(newVal:boolean){
       this.Show = newVal
   }

   @Emit("PullShow")
   PullShow(data:boolean){
       return data
   }

   @Emit("PullMoneyData")
   PullMoneyData(data:any){
       return data
   }


   @Watch("Money")
   ChangeMoney(){
       this.handleSetData()
   }

   @Watch("Price")
   ChangePrice(){
       this.handleSetData()
   }
   
   @Watch("Num")
   ChangeNum(){
       this.handleSetData()
   }

   @Watch("Pre")
   ChangePre(){
       this.handleSetData()
   }

   @Watch("Post")
   ChangePost(){
       this.handleSetData()
   }
}
